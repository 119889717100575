/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { ROUTES } from "@nf/constants";
import { useRouter } from "next/router";
import { captureMessage } from "@sentry/nextjs";
import styles from "./PageNotFound.module.scss";

import React, { useEffect } from "react";
import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import { LinkWithLocale } from "../LinkWithLocale";

const MediaButton = dynamic(() => import("components/MediaButton"));

export const PageNotFound = () => {
  const { t } = useTranslation("translations");
  const router = useRouter();

  useEffect(() => {
    captureMessage("404 page", {
      level: "info",
      tags: {
        res: router.asPath,
      },
    });
  }, []);

  return (
    <div className={styles.containerContent}>
      <h1 className={styles.notFound}>404 - {t("web_page_not_found")}</h1>
      <p className={styles.errorText}>{t("web_page_not_found_info")}</p>
      <div className={styles.buttonWraper}>
        <LinkWithLocale
          href={{
            pathname: `${ROUTES.HOME}`,
          }}
        >
          <MediaButton variant="primary">
            {t("web_page_not_found_button")}
          </MediaButton>
        </LinkWithLocale>
      </div>
    </div>
  );
};

export default PageNotFound;
